/** @format */

import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Features from "../components/Features"
import FullWidthImage from "../components/FullWidthImage"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageCompareSlider from "../components/ImageCompareSlider"
import ContentSection from "../components/ContentSection"
import { Tab, Tabs } from "@material-ui/core"
import ImageCarousel from "../components/ImageCarousel"

// eslint-disable-next-line
export const IndexPageTemplate = ({
  masthead,
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
}) => {
  const heroImage = getImage(masthead.image) || masthead.image
  const section1Image = getImage(section1.image) || section1.image
  const section2Image = getImage(section2.image) || section2.image
  const section4Image = getImage(section4.image) || section4.image
  const section5Image = getImage(section5.image) || section5.image
  const section7Image = getImage(section7.image) || section7.image

  const [flagSlider, setFlagSlider] = React.useState({
    imgSrcOne: section6[0].imageOne,
    imgSrcTwo: section6[0].imageTwo,
    imgCityName: section6[0].city,
  })

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setFlagSlider({
      imgSrcOne: section6[newValue].imageOne,
      imgSrcTwo: section6[newValue].imageTwo,
      imgCityName: section6[newValue].city,
    })

    setValue(newValue)
  }

  return (
    <div>
      <FullWidthImage img={heroImage} title={masthead.title} />
      <section className="section">
        <div className="container">
          <div className="is-vcentered columns is-multiline">
            <div className="column is-5">
              <h2 className="mb-4 is-size-2 is-size-4-mobile has-text-weight-bold">
                {section1.title}
              </h2>
              <div>
                {section1.body.split("\n").map((text, index) => (
                  <p className="subtitle has-text-grey" key={index}>
                    {text}
                    <br />
                  </p>
                ))}
              </div>
              {/* <p className="subtitle has-text-grey mb-5">
              {section1.body}</p> */}
            </div>
            <div className="column is-6 ml-auto">
              <GatsbyImage
                image={section1Image}
                objectFit={"cover"}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image
                aspectratio={3 / 1}
                // This is a presentational image, so the alt should be an empty string
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-light">
        <div className="container">
          <div className="is-vcentered columns is-multiline my-5 py-3">
            <div className="column is-6 mr-auto">
              <GatsbyImage
                image={section4Image}
                objectFit={"cover"}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image
                aspectratio={3 / 1}
                // This is a presentational image, so the alt should be an empty string
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <div className="column is-5">
              <h2 className="mb-4 is-size-2 is-size-4-mobile has-text-weight-bold">
                {section4.title}
              </h2>
              <p className="subtitle has-text-grey">{section4.body}</p>
              <Link className="button is-primary" to="/the-new-flag">
                {section4.button}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="is-vcentered columns is-multiline">
            <div className="column is-5">
              <h2 className="mb-4 is-size-2 is-size-4-mobile has-text-weight-bold">
                {section5.title}
              </h2>
              <div>
                {section5.body.split("\n").map((text, index) => (
                  <p className="subtitle has-text-grey" key={index}>
                    {text}
                    <br />
                  </p>
                ))}
              </div>
            </div>
            <div className="column is-6 ml-auto">
              <GatsbyImage
                image={section5Image}
                objectFit={"cover"}
                layout="fullWidth"
                aspectratio={3 / 1}
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-light">
        <div className="container">
          <div className="is-vcentered columns is-multiline ">
            <div className="column is-6 mr-auto">
              <ImageCarousel />
            </div>
            <div className="column is-5">
              <h2 className="mb-4 is-size-2 is-size-4-mobile has-text-weight-bold">
                More than a city &#8212; a community
              </h2>
              <p className="subtitle has-text-grey">
                Residents and business owners are proudly flying the new flag in
                all parts of town. The flag is embraced by a broad cross section
                of our citizenry...all part of our diverse, thriving community.
                All part of Raleigh.
              </p>
              <Link className="button is-primary" to="/gallery">
                Visit gallery
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="is-vcentered columns is-multiline">
            <div className="column is-7">
              <h2 className="mb-4 is-size-2 is-size-4-mobile has-text-weight-bold">
                {section7.title}
              </h2>
              {/* <p className="subtitle has-text-grey mb-5">{section7.body}</p> */}
              <div>
                {section7.body.split("\n").map((text, index) => (
                  <p className="subtitle has-text-grey" key={index}>
                    {text}
                    <br />
                  </p>
                ))}
              </div>
              <div class="field is-grouped">
                <Link
                  className="button is-primary mr-3"
                  to={section7.button1.url}
                  style={{ marginRight: "1rem" }}
                >
                  {section7.button1.text}
                </Link>
                <Link className="button is-primary" to={section7.button2.url}>
                  {section7.button2.text}
                </Link>
              </div>
            </div>
            <div className="column is-4 ml-auto">
              <GatsbyImage
                image={section7Image}
                objectFit={"cover"}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image
                aspectratio={3 / 1}
                // This is a presentational image, so the alt should be an empty string
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  masthead: PropTypes.object,
  section1: PropTypes.object,
  section2: PropTypes.shape({
    image: PropTypes.object,
    blurbs: PropTypes.array,
  }),
  section3: PropTypes.shape({
    images: PropTypes.array,
  }),
  section4: PropTypes.object,
  section5: PropTypes.object,
  section6: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      imageOne: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      imageTwo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
  section7: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        masthead={frontmatter.masthead}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
        section3={frontmatter.section3}
        section4={frontmatter.section4}
        section5={frontmatter.section5}
        section6={frontmatter.section6}
        section7={frontmatter.section7}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        masthead {
          title
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section1 {
          title
          body
          button
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section2 {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          blurbs {
            icon
            title
            text
          }
          heading
          description
        }
        section3 {
          images {
            image1 {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            image2 {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            title
          }
          title
          description
        }
        section4 {
          title
          body
          button
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section5 {
          title
          body
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section6 {
          city
          imageOne {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          imageTwo {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        section7 {
          title
          body
          button1 {
            text
            url
          }
          button2 {
            text
            url
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
