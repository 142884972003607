/** @format */

import * as React from "react"
import PropTypes from "prop-types"
import Carousel from "react-material-ui-carousel"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

const ImageCarousel = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-8 has-text-centered m3-5 py-3">
            <div className="columns is-multiline is-centered mb-3">
              <Carousel
                NextIcon={
                  <NavigateNextIcon
                    style={{
                      color: "black",
                    }}
                  />
                }
                PrevIcon={
                  <NavigateBeforeIcon
                    style={{
                      color: "black",
                    }}
                  />
                }
                navButtonsProps={{
                  style: {
                    backgroundColor: "whitesmoke",
                    borderRadius: 0,
                  },
                }}
                animation="slide"
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                className={"fullWidthCarousel"}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "100%",
                  }}
                >
                  <img
                    className="image is-fullwidth"
                    src="/img/nrf-balcony-pole-carousel.jpg"
                    alt=""
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "100%",
                  }}
                >
                  <img
                    className="image is-fullwidth"
                    src="/img/nrf-demos-carousel.jpg"
                    alt=""
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "100%",
                  }}
                >
                  <img
                    className="image is-fullwidth"
                    src="/img/nrf-garden-flag-carousel.jpg"
                    alt=""
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ImageCarousel.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      icon: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default ImageCarousel
