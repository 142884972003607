import React from "react"

const ContentSection = (props) => {
  return (
    <section className={`section ${props.backgroundColorClass}`}>
      <div className="container">
        <div className="columns is-multiline is-centered">
          {/* <div className="column is-8 has-text-centered my-5 py-3"> */}
          <div
            className={`column is-8 ${
              props.textCentered ? "has-text-centered" : ""
            } my-5 py-3`}
          >
            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentSection
